<template>
  <div class="container-fluid mt-4 px-md-5">
    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      :position="$screen.breakpoint == 'sm' ? 'full' : 'right'"
      containerStyle="{width: '80vw'}"
    >
      <PCard class="shadow-0 border-0">
        <template #title>
          <div class="d-flex justify-content-between fix-top">
            <div>
              <a
                class="btn btn-sm py-1 btn-outline-primary me-1"
                :href="activeOffre.fullPhotoUrl"
                download
                target="_blank"
              >
                Télécharger
              </a>
              <ShareNetwork
                network="whatsapp"
                :url="sharingUrl"
                :title="sharableTitle"
                description=""
                quote=""
                hashtags=""
              >
                <a
                  class="btn btn-sm py-1 btn-outline-primary me-1"
                  :href="activeOffre.fullPhotoUrl"
                  download
                >
                  <i class="bi bi-share"></i>
                </a>
              </ShareNetwork>
            </div>
            <div>
              <small class="text-caption"
                >{{ activePostIndex + 1 }}/{{ offres.length }}</small
              >
              <span class="p-buttonset ms-2">
                <PButton
                  @click="gotoPreviousPost"
                  class="p-button-sm p-button-outlined"
                  icon="pi pi-chevron-left"
                />
                <PButton
                  @click="gotoNextPost"
                  class="p-button-sm p-button-outlined"
                  icon="pi pi-chevron-right"
                />
              </span>
              <PButton
                @click.stop="displayDialog = false"
                label="Fermer"
                class="p-button-outlined p-button-sm ms-2"
              />
            </div>
          </div>
          <PDivider />
        </template>
        <template #content>
          <div>
            <div>
              <h4 class="py-0 my-0">
                {{ activeOffre.intitule }}
                <!-- <button class="btn btn-primary">Voir détail</button> -->
              </h4>
              <div>
                <!-- <span>{{ activeOffre.lieuDeDepot }}</span> -->
              </div>

              <div class="text-muted">
                <span
                  >Publié le
                  {{ $moment(activeOffre.dateDebutPub).format("MM/DD/YYYY") }} - offre n°
                  000{{ activeOffre.id }}</span
                >
              </div>
              <div class="row mt-4">
                <div class="col-md-8">
                  <div v-if="activeOffre.typeFichier">
                    <template v-if="typeF">
                      <iframe
                        :src="`https://drive.google.com/viewerng/viewer?url=${activeOffre.fullPhotoUrl}&hl=fr&pid=explorer&efh=true&a=v&chrome=false&embedded=true`"
                        frameborder="0"
                        height="700px"
                        width="100%"
                      ></iframe>
                    </template>
                    <template class="col-md-8" v-else>
                      <PImagePreview
                        :src="activeOffre.fullPhotoUrl"
                        alt="Image Affiche"
                        width="500"
                        preview
                      />

                      <!-- <img
                        :src="activeOffre.fullPhotoUrl"
                        alt="Image Affiche"
                      /> -->
                    </template>
                  </div>
                  <div v-else>
                    <template> Pas de fichier joint </template>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="col-sm- mb-4">
                    <div class="d-flex justify-content-between mb-4 fix-top">
                      <div>
                        <a
                          class="btn btn-sm py-1 btn-outline-primary me-1"
                          :href="activeOffre.fullPhotoUrl"
                          download
                          target="_blank"
                        >
                          Télécharger
                        </a>
                        <ShareNetwork
                          network="facebook"
                          :url="sharingUrl"
                          :title="sharableTitle"
                          description=""
                          quote=""
                          hashtags=""
                        >
                          <a
                            class="btn btn-sm py-1 btn-outline-primary me-1"
                            :href="activeOffre.fullPhotoUrl"
                            download
                          >
                            <i class="bi bi-facebook"></i>
                          </a>
                        </ShareNetwork>
                        <ShareNetwork
                          network="whatsapp"
                          :url="sharingUrl"
                          :title="sharableTitle"
                          description=""
                          quote=""
                          hashtags=""
                        >
                          <a
                            class="btn btn-sm py-1 btn-outline-primary me-1"
                            :href="activeOffre.fullPhotoUrl"
                            download
                          >
                            <!-- <i class="bi bi-whatsapp"></i> -->
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-whatsapp"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"
                              />
                            </svg>
                          </a>
                        </ShareNetwork>
                      </div>
                    </div>
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Métier</p>
                      <p class="text-muted my-0">
                        {{ activeOffre.poste ? activeOffre.poste.libelle : "-" }}
                      </p>
                    </div>
                  </div>
                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Domaine</p>
                      <div class="d-flex flex-wrap justify-content">
                        <div
                          v-for="(domaine, index) in activeOffre.domaines"
                          :key="index"
                        >
                          <span
                            class="badge bg-primary text-white rounded- px-4 me-3 mb-2"
                            ><h6 class="m-0">
                              {{ domaine.description }}
                            </h6></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Niveau d'étude</p>
                      <div class="d-flex flex-wrap justify-content">
                        <span v-for="(niv, index) in activeOffre.niveaus" :key="index">
                          <span
                            class="text-muted my-0 badge badge-primary"
                            v-if="niv.code"
                          >
                            {{ niv.code }}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Nature du contrat</p>
                      <p class="text-muted my-0">
                        {{
                          activeOffre.nature
                            ? activeOffre.nature != null && activeOffre.nature.code
                            : "-"
                        }}
                      </p>
                    </div>
                  </div>

                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Âge limite</p>
                      <p class="text-muted my-0">
                        {{ activeOffre.ageMin || "-" }} -
                        {{ activeOffre.ageMax || "-" }} ans
                      </p>
                    </div>
                  </div>
                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Nombre de poste</p>
                      <div>
                        <p class="text-muted my-0">{{ activeOffre.nombre }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-sm- mb-4">
                    <div class="border p-2 h-100">
                      <p class="my-0 fw-bold">Date clôture</p>
                      <div class="d-flex flex-wrap justify-content">
                        <div>
                          <span
                            ><h6 class="m-0">
                              {{ $moment(activeOffre.dateCloture).format("MM/DD/YYYY") }}
                            </h6></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PDivider />
          <!-- <h4 class="fw-bold mb-3 my-2">Profil souhaité</h4>
          <div>
            <h6 class="fw-bold">Experience</h6>
            <div>{{ activeOffre.annExp }} ans</div>
          </div>
          <div class="mt-4">
            <h6 class="fw-bold">Langues</h6>
            <PTag
              class="me-2"
              severity="warning"
              v-for="(lang, index) in activeOffre.langues"
              :key="index"
              >{{ lang.nom }}</PTag
            >
          </div>

          <div class="mt-4">
            <h6 class="my-0 fw-bold">Niveau d'étude</h6>
            <span v-for="(niv, index) in activeOffre.niveaus" :key="index">
              <span class="text-muted my-0" v-if="niv.code">
                {{ niv.code }}
              </span>
            </span>
          </div> -->
        </template>
      </PCard>
    </PSidebar>

    <div class="bg- mt-2 mb-2">
      <!-- <div class="container text-center">
        <div class="col-sm-8 mx-auto">
          <form class="d-flex my-1">
            <input
              class="form-control"
              type="search"
              placeholder="Rechercher une offre"
              aria-label="Search"
              v-model="mxSearch"
            />
            <button type="submit" class="style_btn btn btn-primary">
              <i class="bi bi-search"></i>Rechercher
            </button>
          </form>
        </div>
      </div> -->
      <div class="col-sm-12 col-md-12">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="border-0 shadow-0 rounded-4">
          <div class="row py-0 p- grid formgrid">
            <div class="col field">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <!-- <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                    </span> -->
                  <PInputText
                    type="text"
                    v-model="mxSearch"
                    @keyup.enter.prevent.stop="mxSubmitQuery"
                    :placeholder="'Rechercher par mot(s) clé(s). Ex chauffeur'"
                  />
                  <!-- <p-multi-select
                        v-model="mxSearchFields"
                        :options="searchFieldsOptions.filter((el) => el.visible)"
                        optionLabel="label"
                        optionValue="value"
                        :editable="true"
                      /> -->
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search me-2"></i>Rechercher</a
                >
              </div>
            </div>
          </div>
          <transition>
            <div class="border-0 shadow-0 mt-2">
              <div class="row p-fluid grid">
                <template v-for="(option, index) in filterOptions">
                  <div
                    class="col-12 col-sm-6 col-md-3 mb-4"
                    v-if="option.visible"
                    :key="index"
                  >
                    <!-- <p-multi-select v-model="option.value" :options="getItemsOf(option.getterName)" :optionLabel="option.labelField" :selectionLimit="3" :placeholder="option.label" optionValue="id" :filter="true" display="chip"/> -->
                    <AsyncMultiSelect
                      v-model="option.value"
                      :multiple="true"
                      :queryUrl="option.queryUrl"
                      :optionLabel="option.labelField"
                      :placeholder="option.label"
                      :displayMode="option.displayMode"
                      :emitObject="false"
                      :searchOptions="[option.labelField]"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>

    <slot name="offreSwitcher"></slot>

    <div class="mb-5">
      <div class="row">
        <div class="col-sm-12 col-md-8">
          <div class="row" v-if="mxLoading">
            <div
              class="field card py-2 border-0 shadow-0 col-12 md:col-6"
              v-for="i in 3"
              :key="i"
            >
              <PSkeleton class="mb-2"></PSkeleton>
              <PSkeleton width="10rem" class="mb-2"></PSkeleton>
              <PSkeleton width="5rem" class="mb-2"></PSkeleton>
              <PSkeleton height="2rem" class="mb-2"></PSkeleton>
              <!-- <PSkeleton width="10rem" height="4rem"></PSkeleton> -->
              <PDivider />
            </div>
          </div>
          <div class="row" v-else>
            <div class="active-filters">
              <div
                class="card shadow-0 border-0 border-bottom-1"
                v-if="mxActiveFiltersCount > 0"
              >
                <div class="card-body">
                  <div class="d-flex flex-wrap align-items-center">
                    <h6 class="text-uppercase" v-if="mxActiveFiltersCount > 0">
                      Filtres appliquées:
                    </h6>
                    <template v-for="(option, index) in filterOptions">
                      <!-- <div v-if="option.value != null && option.value.length != 0" :key="index" class="mb-2 border border-primary pt-2"  style="width: 320px"> -->
                      <PTag
                        severity="warning"
                        class="ms-2 mb-2"
                        v-for="(item, i) in option.value"
                        :key="index + '_' + i"
                      >
                        <small class="p-1 text-caption">
                          {{ item[option.labelField] }}
                          <PButton
                            v-tooltip.top="`Retirer du filtre`"
                            icon="pi pi-times cursor-pointer"
                            class="p-button-danger p-button-sm ms-1 p-0"
                            @click.prevent.stop="
                              option.value = option.value.filter((el) => el != item)
                            "
                            style="height: 14px; width: 14px"
                          />
                        </small>
                      </PTag>
                      <!-- </div> -->
                    </template>
                  </div>
                </div>
              </div>
              <!-- <PDivider class="my-0 py-0" /> -->
            </div>

            <div v-if="offres.length == 0">
              <div class="card shadow-0 border-0">
                <div class="card-body">
                  <h6 class="fw-italic">Aucune offre trouvée pour le filtre actif.</h6>
                </div>
              </div>
            </div>
            <div class="shadow-0">
              <div class="card-body bg-white border">
                <h6>
                  Total <span class="h4">{{ mxTotalCount }}</span> offres retrouvées.
                </h6>
              </div>
            </div>
            <div
              class="col-sm-12 col-lg-12 m justify-content-center cursor-pointer"
              v-for="(offre, index) in offres"
              :key="index"
              @click.prevent="showOffre(index)"
            >
              <div class="border-bottom rounded-1 bg-white h-100 px-2 py-4">
                <div class="d-flex align-items-center">
                  <div
                    class="flex-shrink-0 d-flex justify-content-center align-items-center"
                  >
                    <!-- <div
                      class="bg-primary style_level_offre d-flex flex-column justify-content-center align-items-center"
                    >
                      <h6 class="my-0 text-white">{{ index + 1 }}</h6>
                      <span
                        class="text-white my-0"
                        style="font-size: 8px"
                      ></span>
                    </div> -->
                  </div>
                  <div class="flex-grow-1 ms-2">
                    <p class="mb-0 d-flex justify-content-between" v-if="offre.poste">
                      {{ offre.poste.libelle }}

                      <PButton
                        label="Voir détail"
                        icon="pi pi-eye"
                        class="p-button p-button-raised p-button-sm p-button-rounded p-button-outlined"
                      />
                    </p>

                    <small> {{ offre.intitule }} </small>
                    <br />
                    <!-- <div v-html="offre.descriptionTache" style="height: 150px;" class="text-truncate"></div> -->
                    <div class="row">
                      <div class="col-11 mt-1">
                        <small class="text-muted">
                          <strong>Expériences</strong> :
                          <PTag
                            v-if="offre.annExp"
                            :value="offre.annExp + ' ' + offre.uniteExperience"
                            severity="warning"
                            class="py-1"
                          />
                          | <strong v-if="offre.nature">Contrat</strong> :
                          <PTag
                            v-if="offre.nature"
                            :value="offre.nature.code"
                            severity="warning"
                            class=""
                          />
                          |
                          <strong v-if="offre.dureeHebdomadaire">Temps de travail</strong>
                          :
                          <PTag
                            :value="offre.dureeHebdomadaire"
                            severity="warning"
                            class=""
                          />
                          | <strong v-if="offre.niveaus">Niveaux</strong> :
                          <PTag
                            :value="offre.niveaus.map((niv) => niv.description).join('/')"
                            severity="warning"
                            class=""
                          />
                          | <strong v-if="offre.niveaus">Nombre de postes:</strong> :
                          <PTag :value="offre.nombre" severity="warning" class="" />
                          <br />
                          <strong>Publiée</strong> :
                          <span class="fst-italic">{{
                            $moment(offre.dateDebutPub).fromNow()
                          }}</span>
                          |
                          <strong>Date écheance</strong> :
                          <span class="fst-italic">{{
                            $moment(offre.dateCloture).format("DD-MM-YYYY")
                          }}</span>
                        </small>
                      </div>
                    </div>
                    <div class="mt-2">
                      <PBadge
                        v-for="(d, i) in offre.domaines"
                        severity="secondary"
                        :key="i"
                        :value="d.nom"
                        class="me-2 py-0"
                      />
                    </div>
                  </div>
                  <!-- <strong v-if="offre.nature">Contrat</strong> : <PBadge severity="info" :value="offre.nature.code" class="" ></PBadge> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div class="mx-auto text-center my-5" v-else>
            <div class="d-flex justify-content-center">
              <div class="spinner-" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4">
          <div class="card">
            <div class="card-body">
              <div class="h4 text-uppercase">Les dernières annonces</div>
              <div
                class="annonces-list-item mt-3 border-radius border border-radius-sm"
                v-for="(pub, index) in publicites"
                :key="index"
              >
                <div class="w-100">
                  <img
                    :src="pub.fullImageUrl"
                    width="100%"
                    height="300px"
                    class="img-responsive"
                    alt=""
                  />
                </div>
                <div class="px-3 py-1">
                  {{ pub.titre }}
                </div>
              </div>
              <div
                class="annonces-list-item mt-3 border-radius border border-radius-sm"
                v-if="publicites.length == 0"
              >
                <div
                  class="w-100 fst-italic d-flex justify-content-center align-items-center"
                  style="min-height: 200px"
                >
                  Aucune annonce à afficher
                  <!-- <img :src="pub.fullImageUrl" width="100%" height="300px" class="img-responsive" alt=""> -->
                </div>
                <div class="px-3 py-1">
                  <!-- {{ pub.titre }} -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import AsyncMultiSelect from "../../components/common/AsyncMultiSelect.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    AsyncMultiSelect,
    Multiselect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      loader: true,
      displayDialog: false,
      showAdvancedFilter: false,
      activePostIndex: 0,
      search: "",
      typeF: false,
      fich: "",
      viewModes: [
        { icon: "pi pi-align-left", value: "left" },
        { icon: "pi pi-align-right", value: "Right" },
        { icon: "pi pi-align-justify", value: "Justify" },
      ],
      searchFields: ["competence", "nature"],
      searchFieldsOptions: [
        {
          label: "Intitulé du poste",
          value: "intitule",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "metier",
          value: "",
          displayMode: "classic",
          label: "Inititulé du poste",
          queryUrl: "romes",
          valueKey: "id",
          labelField: "libelle",
          dropdown: true,
          visible: true,

          // items:,
        },
        {
          column: "niveau",
          value: "",
          label: "Niveaux",
          dropdown: true,
          displayMode: "classic",
          labelField: "code",
          valueKey: "id",
          queryUrl: "niveaux",
          visible: true,
          // items:,
        },
        {
          column: "domaine",
          value: "",
          label: "Domaine",
          displayMode: "classic",
          labelField: "nom",
          valueKey: "id",
          queryUrl: "domaines",
          dropdown: true,
          visible: true,
        },
        {
          column: "nature",
          value: "",
          label: "Nature contrat",
          displayMode: "classic",
          labelField: "code",
          valueKey: "id",
          queryUrl: "natures",
          dropdown: true,
          visible: true,
        },
      ],
    };
  },
  created() {
    this.fetchPetiteAnnonces();

    this.mxInitializePaginator({
      queryModel: "offreExterne",
      search: this.search,
      fetcherMethod: "offreExterne/fetchOffresPublished",
      dataGetter: "offreExterne/offres",
      paginationGetter: "offreExterne/pagination",
      autoSubmitQuery: false,
      pagination: {
        sortBy: "id",
        descending: false,
        page: 0,
        rowsPerPage: 20,
        sortingParam: `sort=id,desc`,
      },
      filterOptions: this.filterOptions,
      extraQueryArgsParamBuilder: () => {
        return ``;
      },
      searchFields: this.searchFields,
    });
  },
  computed: {
    ...mapGetters({
      publicites: "portail/publicites",
      offres: "offreExterne/offres",
      domaines: "domaine/domaines",
      departements: "departement/departements",
      natures: "nature/natures",
      niveaux: "niveau/niveaux",
      pieces: "piece/typePieces",
      anneeExpriences: "setting/anneeExpriences",
      romes: "rome/romes",
      ethnies: "ethnie/ethnies",
      offre: "offreExterne/offre",
      entreprises: "entreprise/entreprises",
    }),
    sharableTitle() {
      return (
        `
      *Agence nationale pour l'Emploi (AnpE Bénin)* \n
      *Offres d'emploi*
      ${this.activeOffre.intitule}

    ` +
        (this.activeOffre.poste != null ? this.activeOffre.poste.libelle : "") +
        "_Cliquez sur ce lien pour accéder a l'offre_\n"
      );
    },
    activeOffre() {
      if (this.activePostIndex != null) {
        console.log(this.offres[this.activePostIndex].typeFichier);
        this.fich = this.offres[this.activePostIndex].typeFichier;
        if (this.fich == "pdf") {
          this.typeF = true;
        } else {
          this.typeF = false;
        }
        return this.offres[this.activePostIndex];
      }
    },
    sharingUrl() {
      const route = this.$router.resolve({
        name: "portail.offres.list",
        query: { typeOffre: "offreExterne" },
      });
      const absoluteURL = new URL(route.href, window.location.origin).href;
      return absoluteURL;
    },
    emptyEvent() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      fetchPetiteAnnonces: "portail/fetchPetiteAnnonces",
      fetchOffres: "offreExterne/fetchOffresPublished",
      fetchDomaines: "domaine/fetchDomaines",
      fetchDepartements: "departement/fetchDepartements",
      fetchNiveaux: "niveau/fetchNiveaux",
      fetctNatures: "nature/fetchNatures",
      fetchTypePieces: "piece/fetchTypePieces",
      fetchRomes: "rome/fetchRomes",
      fetchEthnies: "ethnie/fetchEthnies",
      selectoffre: "offreExterne/getOneOffre",
      fetchEntreprises2: "entreprise/fetchEntreprises2",
    }),
    getItemsOf(getterName) {
      return this.$store.getters[getterName];
    },
    showOffre(postIndex) {
      console.log("dsioa");
      this.displayDialog = true;
      this.activePostIndex = postIndex;
    },
    gotoNextPost() {
      this.activePostIndex =
        this.activePostIndex < this.offres.length
          ? this.activePostIndex + 1
          : this.activePostIndex;
      console.log(this.activePostIndex);
    },
    gotoPreviousPost() {
      this.activePostIndex =
        this.activePostIndex > 0 ? this.activePostIndex - 1 : this.activePostIndex;
    },
    getOffres() {
      this.$loading(true);
      this.fetchOffres().then(() => {
        this.$loading(false);
      });
    },

    handleDateClick: function (arg) {
      this.setActiveEvent({
        ...this.emptyEvent,
        datePrevue: arg.dateStr,
        // datePrevue: new Date(this.$moment(arg.dateStr).format('YYYY-MM-DDTHH:mm')),
      });
      this.$refs.activiteEditor.show();
    },
  },
};
</script>

<style>
.p-sidebar-header {
  display: none;
}

.event-box-pending {
  background: #42a5f5;
  background: #66bb6a;
  background: #26c6da;
  background: #7e57c255;
  background: #7e57c245;
  color: #7e57c2 !important;
}

.event-box-done {
  /* background: #66BB6A45;
     color: #66BB6A!important; */
  background: #00b68345;
  color: #00b683 !important;
  color: rgba(0, 0, 0, 0.57) !important;
}
.event-box-danger {
  background: #ffa72645;
  color: #ffa726ff !important;
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-missed {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #fe005345;
  /* color: #FE0053FF!important; */
  /* color: rgba(0, 0, 0, 0.87); */
  color: rgba(0, 0, 0, 0.57) !important;
}

.event-box-pending {
  /* background: #42A5F545;
     color: #42A5F5FF!important; */
  background: #485ffd45;
  color: #485ffdff !important;
}

.event-box-reported {
  background: #085e7d45 !important;
  color: #085e7d !important;
  background: #df711b45 !important;
  color: #df711b !important;
}

.event-box-total {
  background: #22006945 !important;
  color: #220069 !important;
}

.event-box {
  font-weight: 430;
  /* color: #222222!important; */
}
</style>
